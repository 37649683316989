import {
  RequestMethod,
  RequestName,
  RequestProxy,
  useCaller,
} from '~/server/bridges';

export class ContactsService {
  fetch(contactId: number) {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.FETCH_CONTACT,
      `contacts/${contactId}`,
      RequestMethod.GET,
    );
  }
}
