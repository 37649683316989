import { Ref } from 'vue';
import { useNuxtApp } from '#app';
import { isRequestNecessary } from '~/middleware/init.global';
import { type ContactInfo, Page, type Sitemap } from '~/types';
const IS_PREVIEW_ACTIVE = true;

const useSitemapFromServer = (): Ref<Sitemap> => {
  const sitemap: Ref<Sitemap> = ref({} as Sitemap);
  if (process.server) {
    const app = useNuxtApp();
    if (
      app &&
      app.ssrContext &&
      app.ssrContext?.url &&
      isRequestNecessary(app.ssrContext?.url)
    ) {
      if (IS_PREVIEW_ACTIVE) {
        const sitemapData = <Sitemap>app.ssrContext?.event.context.sitemap;

        if (sitemapData) {
          const allPages: unknown[] = [];

          sitemapData.pages?.forEach((page) => {
            if (page) {
              allPages.push(page);
            }
            if (page && page.personalised_pages) {
              page.personalised_pages.forEach((item) => {
                if (page.path.includes(':')) {
                  allPages.push({
                    path: `/personalisedPages/${item._id}${page.path}`,
                    name: `${item._id}-${item.name}`,
                    ...item,
                  });
                } else {
                  allPages.push({
                    path: `/personalisedPages/:personalised_page_id`,
                    name: `${item._id}-${item.name}`,
                    ...item,
                  });
                }
              });
            }
          });

          sitemap.value = {
            ...sitemapData,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            pages: allPages,
          };
        }
      } else {
        sitemap.value = <Sitemap>app.ssrContext?.event.context.sitemap;
      }
    }
  }
  return sitemap;
};

export const useSitemap = (): Ref<Sitemap> =>
  useState<Sitemap>('sitemap', () => useSitemapFromServer().value);

export const useLayoutFromServer = (): Ref<string> => {
  const layout: Ref<string> = ref({} as string);
  if (process.server) {
    const app = useNuxtApp();
    if (
      app &&
      app.ssrContext &&
      app.ssrContext?.url &&
      isRequestNecessary(app.ssrContext?.url)
    ) {
      layout.value = <string>app.ssrContext?.event.context.layout;
    }
  }
  return layout;
};

export const useLayout = (): Ref<string> => {
  return useState<string>('layout', () => useLayoutFromServer().value);
};

export const usePages = () => {
  return useState<Page[]>('pages', (): Ref<Page[]> => {
    const app = useNuxtApp();
    const data: Ref<Page[]> = ref([] as Page[]);

    if (process.server && isRequestNecessary(app.ssrContext?.url)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      data.value = useState('sitemap').value.pages;
    }

    return data;
  });
};

export const useContact = () => {
  return useState<ContactInfo>('contact', (): Ref<ContactInfo> => {
    const app = useNuxtApp();
    const data: Ref<ContactInfo> = ref({} as ContactInfo);

    if (process.server && isRequestNecessary(app.ssrContext?.url)) {
      data.value = <ContactInfo>app.ssrContext?.event.context.contact;
    }

    return data;
  });
};
