export class PropertyCustomFieldsConstants {
  static all = [
    {
      property_option_id: 2,
      name: 'Mains Supply'
    },
    {
      property_option_id: 3,
      name: 'Wind Turbine'
    },
    {
      property_option_id: 4,
      name: 'Solar PV Panels'
    },
    {
      property_option_id: 5,
      name: 'Private Supply'
    },
    {
      property_option_id: 6,
      name: 'Mains Supply'
    },
    {
      property_option_id: 7,
      name: 'Private Supply'
    },
    {
      property_option_id: 8,
      name: 'Mains'
    },
    {
      property_option_id: 9,
      name: 'Private Well'
    },
    {
      property_option_id: 10,
      name: 'Private Spring'
    },
    {
      property_option_id: 11,
      name: 'Private Borehole'
    },
    {
      property_option_id: 12,
      name: 'Private Stream'
    },
    {
      property_option_id: 13,
      name: 'Air Conditioning'
    },
    {
      property_option_id: 14,
      name: 'Central'
    },
    {
      property_option_id: 15,
      name: 'Double Glazing'
    },
    {
      property_option_id: 16,
      name: 'Eco-Friendly'
    },
    {
      property_option_id: 17,
      name: 'Gas'
    },
    {
      property_option_id: 18,
      name: 'Gas Central'
    },
    {
      property_option_id: 19,
      name: 'Night Storage'
    },
    {
      property_option_id: 20,
      name: 'Oil'
    },
    {
      property_option_id: 21,
      name: 'Solar'
    },
    {
      property_option_id: 22,
      name: 'Solar Water'
    },
    {
      property_option_id: 23,
      name: 'Wood Burner'
    },
    {
      property_option_id: 24,
      name: 'Open Fire'
    },
    {
      property_option_id: 25,
      name: 'Biomass Boiler'
    },
    {
      property_option_id: 26,
      name: 'Ground Source Heat Pump'
    },
    {
      property_option_id: 27,
      name: 'Air Source Heat Pump'
    },
    {
      property_option_id: 28,
      name: 'Solar Photovoltaic Thermal'
    },
    {
      property_option_id: 29,
      name: 'Underfloor Heating'
    },
    {
      property_option_id: 30,
      name: 'Solar Thermal'
    },
    {
      property_option_id: 31,
      name: 'Electric Mains'
    },
    {
      property_option_id: 32,
      name: 'Electric Room Heaters'
    },
    {
      property_option_id: 39,
      name: 'ADSL'
    },
    {
      property_option_id: 40,
      name: 'CABLE'
    },
    {
      property_option_id: 41,
      name: 'FTTC'
    },
    {
      property_option_id: 42,
      name: 'FTTP'
    },
    {
      property_option_id: 43,
      name: 'NONE'
    },
    {
      property_option_id: 44,
      name: 'Right of Way Public'
    },
    {
      property_option_id: 45,
      name: 'Right of Way Private'
    },
    {
      property_option_id: 46,
      name: 'Listed Building'
    },
    {
      property_option_id: 47,
      name: 'Required Access'
    },
    {
      property_option_id: 48,
      name: 'River'
    },
    {
      property_option_id: 49,
      name: 'Sea'
    },
    {
      property_option_id: 50,
      name: 'Groundwater'
    },
    {
      property_option_id: 51,
      name: 'Lake'
    },
    {
      property_option_id: 52,
      name: 'Reservoir'
    },
    {
      property_option_id: 53,
      name: 'Gas Mains'
    },
    {
      property_option_id: 54,
      name: 'Other'
    },
    {
      property_option_id: 55,
      name: 'Level access'
    },
    {
      property_option_id: 56,
      name: 'Lift access'
    },
    {
      property_option_id: 57,
      name: 'Ramped access'
    },
    {
      property_option_id: 58,
      name: 'Wet room'
    },
    {
      property_option_id: 59,
      name: 'Wide doorways'
    },
    {
      property_option_id: 60,
      name: 'Step Free Access'
    },
    {
      property_option_id: 61,
      name: 'Level Access Shower'
    },
    {
      property_option_id: 62,
      name: 'Lateral Living'
    },
    {
      property_option_id: 63,
      name: 'Good'
    },
    {
      property_option_id: 64,
      name: 'Some work needed'
    },
    {
      property_option_id: 65,
      name: 'Work required throughout'
    },
    {
      property_option_id: 66,
      name: 'Major renovation required'
    }
  ]

  static getNameById(id: number): string {
    const item = this.all.find(i => i.property_option_id === id);

    return item?.name || '';
  }

  static getNamesByIds(ids: number[]) {
    return this.all.filter(i => ids.includes(i.property_option_id)).map(i => i.name).join(', ');
  }
}
