import type { Component } from '~/types';
import {
  fetchMultipleResource,
  fetchSingleResource,
} from '~/units/core/managers';
import { fetchProperties, fetchProperty } from '~/units/properties/services';
import { RepoStates } from '~/units/core/types';

// properties box section method to fetch properties with component configuration
export const fetchPropertiesManager = async (
  component: Component,
  keyword: string | null = null,
) => {
  await fetchMultipleResource(
    component,
    fetchProperties,
    RepoStates.PROPERTIES_REPO,
    {
      keyword,
    },
  );
};

export const fetchPropertyManager = async (
  component: Component,
  slug: string,
) => {
  await fetchSingleResource(
    component,
    fetchProperty,
    RepoStates.SINGLE_PROPERTY_REPO,
    slug,
  );
};
