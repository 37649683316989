import type { RouterConfig } from '@nuxt/schema';
import { useContact, useLayout, useSitemap } from '~/composables/hydration';

import type { Sitemap } from '~/types';

// https://router.vuejs.org/api/interfaces/routeroptions.html

export default <RouterConfig>{
  routes: (_routes) => {
    const sitemap: Sitemap = useSitemap().value;

    const layout: string = useLayout().value;
    if (sitemap && sitemap.pages) {
      const contact = useContact().value;
      const personalisation = (() => {
        if (contact && contact.personalisation) {
          return contact.personalisation;
        }
        return null;
      })();

      return sitemap.pages.map((page) => {
        const personalisedPageId = (() => {
          if (personalisation) {
            if (page) {
              const profile = (() => {
                if (page.name === 'HOME_VENDOR') {
                  return personalisation.vendor;
                } else if (page.name === 'HOME_TENANTS') {
                  return personalisation.tenant;
                } else if (page.name === 'HOME_BUYER') {
                  return personalisation.buyer;
                } else if (page.name === 'HOME_LANDLORDS') {
                  return personalisation.landlord;
                }
                return personalisation.general;
              })();

              if (profile && page.personalised_pages) {
                const personalisedPage = page.personalised_pages.find(
                  (item) => {
                    if (
                      item.personalisation_settings.funnel_step_id ===
                        profile.step &&
                      item.personalisation_settings.profile_id ===
                        profile.profile_id
                    ) {
                      return item;
                    }
                    return null;
                  },
                );

                if (personalisedPage) {
                  return personalisedPage._id;
                }
              }
            }
          }
          return null;
        })();

        return {
          name: page.name,
          meta: {
            pageId: page._id,
            personalisedPageId,
          },
          path: page.path,
          component: () => import(`~/views/${layout}.vue`),
        };
      });
    }
    return [];
  },

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {};
  },

};
