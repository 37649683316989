import { NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import { RequestMethod, RequestName } from '~/server/bridges';
import type { H3Event } from 'h3';
import { ErrorsHandler } from '~/server/errors';
import { FetchError } from 'ofetch';

export class NetworkCaller {
  /**
   * @description this is function for calling network on client/browser side.
   * @param request
   * @param opts
   */
  async call(request: NitroFetchRequest, opts: NitroFetchOptions) {
    const response = await $fetch(request, {
      ...opts ?? {},
      parseResponse: JSON.parse,
    });

    return response.result;
  }
}

export class ProxyRequest {
  event = null;
  query = {};
  body = undefined;
  method: RequestMethod = 'GET'
  requestName: RequestName;
  path: string;

  constructor(event: H3Event) {
    this.event = event;
  }

  async boot() {
    this.query = getQuery(this.event);
    this.method = this.event.node.req.method as RequestMethod;
    this.body = !['GET', 'HEAD', 'DELETE'].includes(this.method)
      ? await readBody(this.event)
      : undefined;
    this.path = this.event.context.params?.path || '';

    this.requestName = <RequestName>this.query.request_name;
    delete this.query.request_name;

    if (!this.requestName) {
      throw createError({
        statusCode: 400,
        statusMessage: ErrorsHandler.REQUEST_NAME_NOT_SPECIFIED_ISSUE,
      });
    }
  }

  async send(baseURL: string, {
    headers,
    errorMessage,
  } = {}) {
    try {
      return await $fetch(this.path, {
        method: this.method,
        baseURL: baseURL,
        query: this.query,
        headers: {
          ...headers ? headers : {},
          'content-type': 'application/json',
        },
        body: this.body,
        parseResponse: JSON.parse
      });
    } catch (e) {
      throw createError({
        statusMessage: errorMessage || 'Unexpected error',
        statusCode: 400,
        message: 'Error',
        data: (e as FetchError).data,
      });
    }
  }
}