export class BaitStatusesConstants {
  static SSTC = 5;
  static EXCHANGED = 6;
  static COMPLETED = 7;
  static LET = 13;
  static LET_AGREED = 19;
  static UNDER_OFFER = 21;

  static SOLD = [
    BaitStatusesConstants.SSTC,
    BaitStatusesConstants.EXCHANGED,
    BaitStatusesConstants.COMPLETED,
  ];

  static LET_COMPLETED = [
    BaitStatusesConstants.LET,
    BaitStatusesConstants.LET_AGREED,
  ];
}
