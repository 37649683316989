export interface PropertiesQueryForRepo {
  limit: number;
  offset: number;
}

export interface PropertyPriceType {
  property_price_type_id: number;
  name: string;
}

export interface PropertyAddress {
  full_address: string;
  address_2: string;
  address_3: string;
  address_4: string;

  lat: number;
  long: number;
}

export interface PropertyPrice {
  property_price_type_id: number;
  price: number;
  property_price_types: Array<PropertyPriceType> | undefined;
  property_price_type: PropertyPriceType | undefined;
}

export interface PropertyBranch {
  branch_address: {
    full_address: null | string;
  };
  branch_departments: {
    department_id: number;
    branch_department_communication_infos: {
      communication_method_id: 7 | 2;
      value: string;
    }[];
  }[];
  branch_id: number;
  name: string;
}

export interface PropertyMedia {
  display: boolean;
  sort_order: number;
  media_path?: {
    small: string;
    large: string;
  };
  property_profile_media_type_id: number;
  property_profile_media_id: number;
  deleted_at: Date | null;

  name: string | null;
  extension: string | null;
  media_link: string | null;
  original_file_path: string | null;
}

export interface PropertyInfo {
  property_profile_id: number;
  advert_heading: string;
  advert_summary: string;
  prices: PropertyPrice[];
  property_price_type_id: number;
  property_photo_single?: {
    media_path?: {
      large: string;
    };
  };
  transaction_type_id: 1 | 2;

  bait?: {
    bait_status_id: number;
  };

  property: {
    bedroom: number;
    bathroom: number;
    reception: number;
    property_type?: {
      name: string;
      property_type_id: number;
    };
    property_style?: {
      name: string;
      property_style_id: number;
    };
    property_address: PropertyAddress;
  };
  head_title: string;
  branch: PropertyBranch;
  property_profile_features?: {
    features: string[];
  };
  public_medias: PropertyMedia[];

  property_parking?: {
    name: string;
  };
  floor_area: string;

  tenure_type?: {
    name: string;
  };

  age_of_property: {
    name: string;
  };
  year_built: number;

  council_tax_band?: {
    name: string;
  };
  advert_description: string;

  available_date: Date;

  let_type: {
    name: string;
  };

  lettings_deposit: string | number;

  property_profile_furnished_type: {
    name: string;
  };

  house_flat_share: string;

  crucial_dates?: {
    date_on_market?: {
      lifesycle_search: Date | null;
    };
  };


  sewerage: number;
  water: number;
  conditions: number;
  flooded_in_last_five_years: boolean;
  sources_of_flooding: number[];
  flood_defences: boolean;
  heating_type: number[];
  accessibility: number[];
  electricity: number[];
  restrictions: number[];
  broadband: number[];

  property_is_visible_for_everyone?: boolean;
}

export interface PropertyItem {
  info: PropertyInfo;
  percentage?: string;
  is_featured?: boolean;
  flash_text?: string;
}

export interface PropertyPriceComponent {
  price: string | null;
  numberPrice: number | null;
  type: {
    name: string | null;
  };
}

export type Properties = {
  query: PropertiesQueryForRepo;
  data: PropertyItem[];
  identifier: string;
  total: number;
};

export type SingleProperty = {
  query: {
    slug: string;
  };
  data: PropertyInfo;
  identifier: string;
};

export type PropertiesRepo = Properties[];
export type SinglePropertyRepo = SingleProperty[];

export interface PropertyMediaComponent {
  background: string;
  mediaLink: string;
  preview: string;
  isFiltered: boolean;
  isImage: boolean;
  isIframePlayable: boolean;
  isVirtualTour: boolean;
  isDownloadable: boolean;
  property_profile_media_id: number;
}

export interface PropertyMediaByTypeComponent {
  isPDF: boolean;
  image: string | null;
  fileURL: string | null;
}

export type CustomPropertyStatusTitle =
  | 'SSTC'
  | 'Let Agreed'
  | 'Under Offer'
  | string
  | null;

export interface PropertyItemComponent {
  slug: string;
  property_profile_id: number;
  order: string;
  image: string;
  bedroom: number;
  bathroom: number;
  reception: number;

  price: PropertyPriceComponent;

  advert_heading: string;
  display_address: string;
  branch_phone_number: string | null;
  branch_email: string | null;
  features: string[];
  medias: PropertyMediaComponent[];
  featured_video: PropertyMediaComponent | undefined;
  criteria_list: any;
  lettings_criteria_list: any;
  flood_list: any;
  additional_information_list: any;
  is_rent: boolean;
  description: string;
  summary: string | null;

  images: PropertyMediaComponent[];

  match_percentage: string | undefined;

  property_position: {
    lat: number;
    long: number;
  } | null;

  map_position: {
    lat: number | undefined;
    lng: number | undefined;
  } | null;

  floor_plans: PropertyMediaByTypeComponent[];
  epcs: PropertyMediaByTypeComponent[];
  extra_medias: {
    name: string | null;
    extension: string | null;
    mediaLink: string;
  }[];

  custom_property_status_title: CustomPropertyStatusTitle;
  online_date: Date | null;
  online_date_from_now: string;

  transaction_type_id: 1 | 2;
  branch_id: number | undefined;
  full_address: string | undefined;

  virtual_tour: PropertyMediaComponent | undefined;

  detail_page_url: string;
  is_locked: boolean;
}

export interface PropertySearchFilter {
  mile: number;
  keyword: string;

  is_available: boolean;
}

interface LocationBounds {
  northeast?: {
    lat: number;
    lng: number;
  };
  southwest?: {
    lat: number;
    lng: number;
  };
}

export type LocationComponent = {
  bounds?: LocationBounds;
  location: string;
  location_type: string;
  place_id: string;
  lat: number;
  lng: number;

  distance: number;
};

export interface Location extends LocationComponent {
  distance: number; // mile
}

export enum PFilterInclusionBase {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterBillsIncludes {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterPetsAllowed {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterRetirementHomes {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterHouseFlatShare {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterShortLet {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterNewHomes {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterSharedOwnership {
  INCLUDE = 1,
  EXCLUDE = 2,
  SHOW_ONLY = 3,
}

export enum PFilterMustHaves {
  GARDEN = 'garden',
  PARKING = 'parking',
}

export enum PFilterFurnishing {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  ANY = null,
  FURNISHED = 1,
  PART_FURNISHED = 2,
  UNFURNISHED = 3,
}

export enum PFilterSortOptions {
  SUGGESTED = 'best_match',
  HIGHEST_PRICE = '-price',
  LOWEST_PRICE = 'price',
  NEWEST_LISTED = '-added_at',
  OLDEST_LISTED = 'added_at',
}

export type PFilterPropertyTypes = {
  property_style_ids: number[];
  property_type_id: number;
};

export enum PFilterSearchType {
  SALES = 'sales',
  LETTINGS = 'lettings',
}

export interface PropertiesFilter {
  locations?: Location[];
  min_bedroom?: number | null;
  max_bedroom?: number | null;

  min_bathroom?: number | null;
  max_bathroom?: number | null;

  min_reception?: number | null;
  max_reception?: number | null;

  min_price?: number;
  max_price?: number;

  new_homes?: PFilterNewHomes;
  shared_ownership?: PFilterSharedOwnership;

  must_haves?: PFilterMustHaves[];
  retirement_homes?: PFilterRetirementHomes;
  property_types?: PFilterPropertyTypes[];

  // lettings
  short_let?: PFilterShortLet;
  house_flat_share?: PFilterHouseFlatShare;
  pets_allowed?: PFilterPetsAllowed;
  bills_included?: PFilterBillsIncludes;
  furnishing?: PFilterFurnishing;
}

export interface PropertiesQuery {
  is_available?: boolean;
}

export type PropertyFilterRouteQuery = PropertiesFilter &
  PropertiesQuery & {
    sort?: PFilterSortOptions;
  };

export type PropertiesFilterRouteQueryKeys = keyof PropertyFilterRouteQuery;

export type PropertiesFilterKeys = keyof PropertiesFilter;

export type PropertiesInitialConfig = {
  is_available: boolean;
};
