import { Component, VariableValueType } from '~/types';
import { BlogsRepo, SingleBlogRepo } from '~/units/blogs/types';

import { PropertiesRepo, SinglePropertyRepo } from '~/units/properties/types';
import { States } from '~/units/core/types';

export const useVariant = (component: Component) => {
  if (
    component &&
    component.component_variant &&
    component.component_variant.name
  ) {
    return component.component_variant.name;
  }
  return null;
};

export const useDataFromState = (stateName: States) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-empty-function
  const data: any = useState(stateName, () => {});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setData = (newData: any) => {
    if (!data.value) {
      data.value = newData;
    } else {
      data.value = [...data.value, ...newData];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const replaceData = (newData: any) => {
    data.value = newData;
  };

  const removeData = () => {
    data.value = null;
  };

  return {
    data: data.value,
    setData,
    replaceData,
    removeData,
  };
};


export const getFromState = (
  stateName: States,
):
  | BlogsRepo
  | null
  | PropertiesRepo
  | boolean
  | SingleBlogRepo
  | SinglePropertyRepo => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = useState(stateName);
  if (data) {
    return data.value;
  }
  return null;
};

export const getVariable = (component: Component, key: string) => {
  const parts = key.split('-');
  const valueType: VariableValueType = <VariableValueType>parts[1];
  if (component && component.variables) {
    const value = component.variables[key];
    if (valueType === 'number') {
      return +value;
    }
    if (valueType === 'bool') {
      return value === 'true' ? true : value === 'false' ? false : null;
    }

    return value;
  }
  return null;
};
