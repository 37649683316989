import {
  RequestMethod,
  RequestName,
  RequestProxy,
  useCaller,
} from '~/server/bridges';

export class SubscriptionsService {
  fetch() {
    return useCaller(
      RequestProxy.LS_AXON,
      RequestName.FETCH_SUBSCRIPTIONS,
      `subscription_types`,
      RequestMethod.GET,
    );
  }
}
