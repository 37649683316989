import {
  RequestMethod,
  RequestName,
  RequestProxy,
  useCaller,
} from '~/server/bridges';
import {
  MultipleFetchFunction,
  SingleFetchFunction,
} from '~/units/core/managers';

export class PropertiesService {
  async fetchProperties(query = {}, body = {}) {
    try {
      return await useCaller(
        RequestProxy.S_AXON,
        RequestName.FETCH_PROPERTIES,
        'properties/matched',
        RequestMethod.POST,
        query,
        body,
      );
    } catch (e) {
      return {
        data: [],
        meta: {},
      };
    }
  }
}

// properties box section method to fetch properties with component configuration
export const fetchProperties: MultipleFetchFunction = async (
  limit: number,
  offset = 0,
  order: '-added_at' | string = '-added_at',
  filter = {},
  body,
) => {
  try {
    return await useCaller(
      RequestProxy.S_AXON,
      RequestName.FETCH_PROPERTIES,
      'properties/matched',
      RequestMethod.POST,
      {
        is_website: true,
        __limit: limit,
        __offset: offset,
        __order_by: order,
        ...filter,
      },
      body,
    );
  } catch (e) {
    return [];
  }
};

export const fetchProperty: SingleFetchFunction = async (id: string) => {
  try {
    return await useCaller(
      RequestProxy.S_AXON,
      RequestName.FETCH_PROPERTY,
      `properties/published/${id}`,
      RequestMethod.GET,
    );
  } catch (e) {
    return [];
  }
};
