<template>
  <div>website not found</div>
</template>

<script lang="ts">
import { useMonitoringStore } from '~/units/monitoring/store';

export default defineNuxtComponent({
  async setup() {
    console.log('HELLO')
    if (process.server) {
      const url = useRequestURL();
      const headers = useRequestHeaders();

      console.log(url, 'REQUEST URL IN ERRORS');

      if (url.searchParams.get('message') === 'WEBSITE_NOT_FOUND') {
      } else if (url.pathname === '/__nuxt_error') {
        await clearError();
        const requestedPath = url.searchParams.get('url').replaceAll('/', '');

        const monitoringStore = useMonitoringStore();

        await monitoringStore.sendLog({
          meta: {
            status_code: 404,
            path: requestedPath,
            origin: url.origin,
            host: url.host,
            headers: {
              'user-agent': headers['user-agent'],
            },
          },
        });

        try {
          await navigateTo({
            path: '/not-found',
            query: {
              path: requestedPath,
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
});
</script>
