import { defineStore } from 'pinia';
import {
  ContactsService,
  SubscriptionsService,
} from '~/units/contacts/services';
import {
  RequestMethod,
  RequestName,
  RequestProxy,
  useCaller,
} from '~/server/bridges';
import { SessionKeys, SessionStore } from '~/units/core/types';

import type { ContactInfo } from '~/types';

export const useContactsStore = defineStore({
  id: 'contacts',
  state: (): {
    subscriptions: any[];
    contactInfo: ContactInfo | null;
    contact: any;
    sessionContent: {
      data?: {
        neuron_session_id?: string;
        user?: SessionStore;
      };
    } | null;
  } => {
    return {
      subscriptions: [],

      contactInfo: null,

      contact: null,

      sessionContent: null,
    };
  },
  actions: {
    async updateSession(applicantKeyId?: string | null, contactId?: number) {
      const body: {
        [SessionKeys.SESSION]?: string;
        [SessionKeys.CONTACT_ID]?: number;
      } = {};
      if (applicantKeyId) {
        body[SessionKeys.SESSION] = applicantKeyId;
      }

      if (contactId) {
        body[SessionKeys.CONTACT_ID] = contactId;
      }

      await useCaller(
        RequestProxy.INTERNAL,
        RequestName.TOUCH_POST,
        'touch',
        RequestMethod.POST,
        {},
        body,
      );
    },

    setSessionToStore(sessionContent: any) {
      this.sessionContent = sessionContent;
    },

    async fetchSubscriptions() {
      if (!this.subscriptions.length) {
        const subscriptionsService = new SubscriptionsService();

        const result = await subscriptionsService.fetch();
        if (result && result.data) {
          this.subscriptions = result.data;
        }
      }
    },

    async fetchContact(contactId: number) {
      try {
        if (!this.contact) {
          const contactsService = new ContactsService();
          const { data } = await contactsService.fetch(contactId);
          this.contact = data;
        }
      } catch (e) {}
    },

    async fetchContactBySession() {
      try {
        if (!this.contact && this.contactInfo && this.contactInfo.contact_id) {
          const contactsService = new ContactsService();
          const { data } = await contactsService.fetch(
            this.contactInfo.contact_id,
          );
          this.contact = data;
        }
      } catch (e) {}
    },

    setContactInfo(data: ContactInfo) {
      this.contactInfo = data;
    },

    getPreferredCommunicationInfoIndex(infos: any, type: any) {
      const preferredInfo = infos.findIndex(
        (info: any) => info.communication_method_id === type && info.preferred,
      );
      if (preferredInfo > -1) {
        return preferredInfo;
      }

      const methodMatchedInfo = infos.findIndex(
        (info: any) => info.communication_method_id === type,
      );
      if (methodMatchedInfo > -1) {
        return methodMatchedInfo;
      }

      return null;
    },

    getPreferredCommunicationInfoValue(infos: any, type: any) {
      const result = this.getPreferredCommunicationInfoIndex(infos, type);
      if (result !== null) {
        return infos[result].value;
      }
      return null;
    },
  },
  getters: {
    sessionData(state): SessionStore | undefined {
      return state?.sessionContent?.data?.user;
    },

    neuronSessionId(state): string | undefined {
      return state?.sessionContent?.data?.neuron_session_id;
    },

    isLogged(state) {
      return state.contact && state.contactInfo;
    }
  },
});
