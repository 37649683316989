import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_jmBwdqbgTJ from "/vercel/path0/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_6wEQMY3tee from "/vercel/path0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import globalMixins_plugin_09c2kq3z3X from "/vercel/path0/plugins/globalMixins.plugin.ts";
import toastClientNotification_plugin_client_gZplT3wotd from "/vercel/path0/plugins/toastClientNotification.plugin.client.ts";
import vercelAnalytic_client_Jlq8v0CiPA from "/vercel/path0/plugins/vercelAnalytic.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  motion_jmBwdqbgTJ,
  nuxt_plugin_6wEQMY3tee,
  plugin_w2VlvAEcpa,
  globalMixins_plugin_09c2kq3z3X,
  toastClientNotification_plugin_client_gZplT3wotd,
  vercelAnalytic_client_Jlq8v0CiPA
]