import TransactionTypesConstants from '~/constants/transactionTypes.constants';
import PropertyTypesConstants from '~/constants/propertyTypesConstants';
import PropertyPriceConstants from '~/composables/propertyPrice.constants';
import { currencyFormat, getIdOfYoutubeVideo } from '~/helpers';
import type {
  PropertyPrice,
  PropertyPriceComponent,
  PropertyAddress,
  PropertyItem,
  PropertyBranch,
  PropertyInfo,
  PropertyMedia,
  PropertyItemComponent,
  PropertyMediaComponent,
  PropertyMediaByTypeComponent,
  CustomPropertyStatusTitle,
} from '~/units/properties/types';
import CommunicationMethodsConstants from '~/units/core/constants/communication.methods';
import MediaTypesConstants from '~/units/core/constants/mediaTypes.constants';
import { fromNow, uiDateFormat } from '~/units/core/providers/date.provider';
import { BaitStatusesConstants } from '~/units/core/constants/baitStatuses.constants';
import { PropertyCustomFieldsConstants } from '~/units/properties/constants/propertyCustomFields.constants';
import { NOT_FOUND_IMAGE } from '~/constants/ui.constants';
import { useContactsStore } from '~/units/contacts/store';

const CDN_URL = 'https://lifesycle-cdn.s3.eu-west-2.amazonaws.com/';

const getAdvertHeading = (
  heading: string,
  info: {
    bedroom: number;
    property_style?: { name: string | undefined };
    property_type?: {
      name: string | undefined;
      property_type_id: number | undefined;
    };
    transaction_type_id: number;
  },
) => {
  if (heading) {
    return heading;
  }
  const action =
    TransactionTypesConstants.SALES === info.transaction_type_id
      ? TransactionTypesConstants.SALES_ACTION
      : TransactionTypesConstants.LETTINGS_ACTION;
  let title = '';

  if (info.bedroom) {
    title = `${info.bedroom} Bedroom`;
  }

  if (info.property_style) {
    title = `${title} ${info.property_style.name}`;
  }

  if (info.property_type) {
    // if property type is not apartment, add type to title
    if (
      info.property_type &&
      info.property_type?.property_type_id &&
      ![
        PropertyTypesConstants.APARTMENT_TYPE,
        PropertyTypesConstants.BUNGALOW_TYPE,
      ].includes(info.property_type?.property_type_id)
    ) {
      title = `${title} ${info.property_type.name}`;
    }
  }

  if (action) {
    title = `${title} for ${action}`;
  }

  return title;
};

const getPrice = (
  priceTypeId: number,
  prices: PropertyPrice[],
): PropertyPriceComponent => {
  if (priceTypeId && prices && prices.length > 0) {
    const propertyPrice = prices.find(
      (price) => price.property_price_type_id === priceTypeId,
    );
    if (propertyPrice) {
      let priceType = null;
      // get from php endpoint directly.
      if (propertyPrice.property_price_type) {
        priceType = propertyPrice.property_price_type;
        // get from ES endpoint.
      } else if (propertyPrice.property_price_types) {
        priceType = propertyPrice.property_price_types.find(
          (type) =>
            type.property_price_type_id ===
            propertyPrice.property_price_type_id,
        );
      }

      // remove price type if property price is default
      if (
        priceType &&
        priceType.property_price_type_id === PropertyPriceConstants.DEFAULT
      ) {
        priceType = null;
      }

      const response = {
        type: {
          property_price_type_id: propertyPrice.property_price_type_id,
          name: priceType ? priceType.name : null,
        },
      };

      if (propertyPrice.property_price_type_id === PropertyPriceConstants.POA) {
        return {
          price: null,
          numberPrice: null,
          ...response,
        };
      }
      return {
        price: currencyFormat(propertyPrice.price),
        numberPrice: propertyPrice.price,
        ...response,
      };
    }
  }
  return {
    price: null,
    numberPrice: null,
    type: {
      name: null,
    },
  };
};

const getAddress = (headTitle: string, address: PropertyAddress) => {
  if (headTitle) {
    return headTitle;
  }
  const firstPartOfAddress = (() => {
    if (address.address_2) {
      return address.address_2;
    }
    if (address.address_3) {
      return address.address_3;
    }
    return '';
  })();

  return `${firstPartOfAddress ? `${firstPartOfAddress}, ` : ''} ${
    address.address_4 ? address.address_4 : ''
  }`;
};

const getBranchCommunicationInfo = (
  branch: PropertyBranch,
  propertyTransactionTypeId: number,
  communicationMethods: number[],
) => {
  if (
    branch &&
    branch.branch_departments &&
    branch.branch_departments.length > 0
  ) {
    // find department by property transaction type
    let branchDepartment = branch.branch_departments.find(
      (i) => i.department_id === propertyTransactionTypeId,
    );
    if (!branchDepartment) {
      // find department that is both
      branchDepartment = branch.branch_departments.find(
        (i) => i.department_id === TransactionTypesConstants.BOTH,
      );
      if (!branchDepartment) {
        // set first department of branch
        branchDepartment = branch.branch_departments[0];
      }
    }

    if (
      branchDepartment &&
      branchDepartment.branch_department_communication_infos &&
      branchDepartment.branch_department_communication_infos.length > 0
    ) {
      // find phone number for landline and mobile
      const communicationInfo =
        branchDepartment.branch_department_communication_infos.find(
          (communication) =>
            communicationMethods.includes(
              communication.communication_method_id,
            ),
        );
      if (communicationInfo) {
        return communicationInfo.value;
      }
    }
    return null;
  }
  return null;
};

const getBranchPhone = (
  branch: PropertyBranch,
  propertyTransactionTypeId: number,
) => {
  return getBranchCommunicationInfo(
    branch,
    propertyTransactionTypeId,
    CommunicationMethodsConstants.PHONE_NUMBERS,
  );
};

const getBranchEmail = (
  branch: PropertyBranch,
  propertyTransactionTypeId: number,
) => {
  return getBranchCommunicationInfo(branch, propertyTransactionTypeId, [
    CommunicationMethodsConstants.EMAIL,
  ]);
};



const getIdOfVimeoVideo = (url: string) => {
  if (url) {
    const match = url.match(/\d+$/);
    if (match) {
      return match[0];
    }
  }
  return null;
};

const extractTextFromHtml = (html: string) => {
  return html.replace(/<[^>]+>/g, '');
};

const getMedias = (publicMedias: PropertyMedia[]): PropertyMediaComponent[] => {
  // gets available gallery medias
  const availableMedias = publicMedias
    .filter(
      (media) =>
        MediaTypesConstants.GALLERY_TYPES.includes(
          media.property_profile_media_type_id,
        ) &&
        media.display &&
        !media.deleted_at,
    )
    .sort((a, b) => a.sort_order - b.sort_order);

  let galleryMedias: PropertyMedia[] = [];
  let defaultImage = '';

  // gets default image of gallery
  const firstImageIndex = availableMedias.findIndex(
    (media) =>
      media.property_profile_media_type_id === MediaTypesConstants.PHOTOS,
  );

  if (
    firstImageIndex > -1 &&
    galleryMedias &&
    galleryMedias[0] &&
    galleryMedias[0].media_path
  ) {
    galleryMedias.push(availableMedias[firstImageIndex]);
    defaultImage = `${CDN_URL}${galleryMedias[0].media_path.small}`;
    availableMedias.splice(firstImageIndex, 1);
  } else {
    defaultImage = '/cdn/content/none-img.png';
  }

  // gets default video of gallery
  const firstVideoIndex = availableMedias.findIndex(
    (media) =>
      media.property_profile_media_type_id === MediaTypesConstants.VIDEO,
  );

  if (firstVideoIndex > -1) {
    galleryMedias.push(availableMedias[firstVideoIndex]);
    availableMedias.splice(firstVideoIndex, 1);
  }

  // gets virtual tour of gallery
  const firstVirtualTourIndex = availableMedias.findIndex(
    (media) =>
      media.property_profile_media_type_id ===
      MediaTypesConstants.VIRTUAL_TOURS,
  );

  if (firstVirtualTourIndex > -1) {
    galleryMedias.push(availableMedias[firstVirtualTourIndex]);
    availableMedias.splice(firstVirtualTourIndex, 1);
  }

  const restOfPhotos = availableMedias.filter(
    (media) =>
      media.property_profile_media_type_id === MediaTypesConstants.PHOTOS,
  );

  const restOfMedias = availableMedias.filter(
    (media) =>
      media.property_profile_media_type_id !== MediaTypesConstants.PHOTOS,
  );
  galleryMedias = [...galleryMedias, ...restOfPhotos, ...restOfMedias];

  return galleryMedias.map((media) => {
    const newMedia = {
      background: '',
      mediaLink: '',
      preview: '',
      isFiltered: false,
      isImage: false,
      isIframePlayable: false,
      isVirtualTour: false,
      isDownloadable: false,
      property_profile_media_id: media.property_profile_media_id,
    };

    if (media.property_profile_media_type_id === MediaTypesConstants.PHOTOS) {
      newMedia.background = `${CDN_URL}${media.media_path?.large}`;
      newMedia.isDownloadable = true;
      newMedia.isImage = true;
    } else if (
      media.property_profile_media_type_id === MediaTypesConstants.VIDEO
    ) {
      newMedia.isFiltered = true;
      newMedia.isIframePlayable = true;

      if (media.media_link) {
        if (
          media.media_link.includes('youtube.com') ||
          media.media_link.includes('youtu.be')
        ) {
          const videoYoutubeId = getIdOfYoutubeVideo(media.media_link);
          if (videoYoutubeId) {
            newMedia.mediaLink = `//www.youtube.com/embed/${videoYoutubeId}`;
            newMedia.background = `//i3.ytimg.com/vi/${videoYoutubeId}/hqdefault.jpg`;
          } else {
            newMedia.mediaLink = media.media_link;
          }
        }
        if (media.media_link.includes('vimeo.com')) {
          const videoVimeoId = getIdOfVimeoVideo(media.media_link);
          if (videoVimeoId) {
            newMedia.mediaLink = `https://player.vimeo.com/video/${videoVimeoId}`;
            newMedia.background = `//i3.ytimg.com/vi/${videoVimeoId}/hqdefault.jpg`;
          } else {
            newMedia.mediaLink = media.media_link;
          }
        }
      }
    } else if (
      media.property_profile_media_type_id === MediaTypesConstants.VIRTUAL_TOURS
    ) {
      newMedia.isFiltered = true;
      newMedia.mediaLink = media.media_link ? media.media_link : '';
      newMedia.isVirtualTour = true;
    }

    // if there is no background, set first image as a background
    if (!newMedia.background) {
      newMedia.background = defaultImage;
    }

    return newMedia;
  });
};

const getCriteriaOfProperty = (info: PropertyInfo) => {
  const list: { title: string; value: string | number }[] = [];

  if (info.property.property_type) {
    list.push({
      title: 'Property Type',
      value: info.property.property_type.name,
    });
  }
  if (info.property.property_style) {
    list.push({
      title: 'Property Style',
      value: info.property.property_style.name,
    });
  }

  if (info.property_parking) {
    list.push({
      title: 'Parking',
      value: info.property_parking.name,
    });
  }

  if (info.floor_area) {
    list.push({
      title: 'Floor Area',
      value: info.floor_area,
    });
  }

  if (info.tenure_type) {
    list.push({
      title: 'Tenure Type',
      value: info.tenure_type.name,
    });
  }

  if (info.age_of_property) {
    list.push({
      title: 'Age Of Property',
      value: info.age_of_property.name,
    });
  }

  if (info.year_built) {
    list.push({
      title: 'Year Built',
      value: info.year_built,
    });
  }

  if (info.council_tax_band) {
    list.push({
      title: 'Council Tax Band',
      value: info.council_tax_band.name,
    });
  }

  if (info.sewerage) {
    list.push({
      title: 'Sewerage',
      value: PropertyCustomFieldsConstants.getNameById(info.sewerage),
    });
  }

  if (info.water) {
    list.push({
      title: 'Water',
      value: PropertyCustomFieldsConstants.getNameById(info.water),
    });
  }

  if (info.conditions) {
    list.push({
      title: 'Condition',
      value: PropertyCustomFieldsConstants.getNameById(info.conditions),
    });
  }

  return list;
};

const getFloodOfProperty = (info: PropertyInfo) => {
  const list: { title: string; value: string | number }[] = [];

  if (info.flooded_in_last_five_years) {
    list.push({
      title: 'Flood in last five years',
      value: info.flooded_in_last_five_years ? 'Yes' : '',
    });

    list.push({
      title: 'Sources of flooding',
      value: PropertyCustomFieldsConstants.getNamesByIds(info.sources_of_flooding),
    });

    list.push({
      title: 'Flood Defences',
      value: info.flood_defences ? 'Yes' : 'No',
    });
  }

  return list;
};

const getAdditionalInformationOfProperty = (info: PropertyInfo) => {
  const list: { title: string; value: string | number }[] = [];

  if (info.heating_type && info.heating_type.length) {
    list.push({
      title: 'Heating',
      value: PropertyCustomFieldsConstants.getNamesByIds(info.heating_type),
    });
  }

  if (info.accessibility && info.accessibility.length) {
    list.push({
      title: 'Accessibility',
      value: PropertyCustomFieldsConstants.getNamesByIds(info.accessibility),
    });
  }

  if (info.electricity && info.electricity.length) {
    list.push({
      title: 'Electricity',
      value: PropertyCustomFieldsConstants.getNamesByIds(info.electricity),
    });
  }

  if (info.restrictions && info.restrictions.length) {
    list.push({
      title: 'Restrictions',
      value: PropertyCustomFieldsConstants.getNamesByIds(info.restrictions),
    });
  }

  if (info.broadband && info.broadband.length) {
    list.push({
      title: 'Broadband',
      value: PropertyCustomFieldsConstants.getNamesByIds(info.broadband),
    });
  }

  return list;
};

const getLettingsCriteriaOfProperty = (info: PropertyInfo) => {
  const list: { title: string; value: string | number }[] = [];

  if (info.property.property_type) {
    list.push({
      title: 'Date Available',
      value: uiDateFormat(info.available_date),
    });
  }
  if (info.let_type) {
    list.push({
      title: 'Let Type',
      value: info.let_type.name,
    });
  }

  if (info.lettings_deposit) {
    list.push({
      title: 'Deposit',
      value: currencyFormat(+info.lettings_deposit),
    });
  }

  if (info.property_profile_furnished_type) {
    list.push({
      title: 'Furnish Type',
      value: info.property_profile_furnished_type.name,
    });
  }

  if (info.house_flat_share) {
    list.push({
      title: 'House Flat Share',
      value: 'Yes',
    });
  }

  return list;
};

const getDescription = (info: PropertyInfo) => {
  if (
    info.advert_description &&
    extractTextFromHtml(info.advert_description) &&
    extractTextFromHtml(info.advert_description).trim()
  ) {
    return info.advert_description;
  }
  return '';
};

const getSummary = (info: PropertyInfo) => {
  if (info.advert_summary) {
    return info.advert_summary;
  }
  if (info.advert_description) {
    return extractTextFromHtml(info.advert_description);
  }
  return null;
};

const getPropertyPosition = (info: PropertyInfo) => {
  if (info && info.property && info.property.property_address) {
    return {
      lat: +info.property.property_address.lat,
      long: +info.property.property_address.long,
    };
  }
  return null;
};

const getMediasByType = (
  medias: Array<PropertyMedia>,
  types: Array<number>,
): PropertyMedia[] => {
  // filter medias by type, display and deleted status, after that sort them by sort_order
  return medias
    .filter((media) => {
      return (
        types.includes(media.property_profile_media_type_id) &&
        media.display &&
        !media.deleted_at
      );
    })
    .sort((a, b) => a.sort_order - b.sort_order);
};

export const getMediasByMapping = (
  medias: Array<PropertyMedia>,
  type: number,
): PropertyMediaByTypeComponent[] => {
  return getMediasByType(medias, [type]).map((media) => {
    const isPDF = media.extension?.toLowerCase() === 'pdf';

    const image = (() => {
      if (media.media_path && media.media_path.large) {
        return `${CDN_URL}${media.media_path.large}`;
      }
      return null;
    })();

    const fileURL = (() => {
      if (isPDF) {
        return `${CDN_URL}${media.original_file_path}`;
      }
      return null;
    })();

    return {
      isPDF,
      image,
      fileURL,
    };
  });
};

const generatePropertySlug = (info: PropertyInfo, address: string) => {
  const convertTextToSlug = (text: string | undefined) => {
    if (text) {
      return text
        .toString()
        .toLowerCase()
        .replaceAll(',', '')
        .replaceAll('/', '')
        .split(' ')
        .map((part) => part.trim())
        .filter((part) => part && part.trim())
        .join('-');
    }
    return '';
  };

  const propertyTypePart = convertTextToSlug(info.property.property_type?.name);
  const transactionTypePart =
    info.transaction_type_id === TransactionTypesConstants.SALES
      ? 'for-sale'
      : 'to-rent';
  const addressPart = convertTextToSlug(address);

  return `${info.property.bedroom}-bedroom${
    propertyTypePart ? `-${propertyTypePart}-` : '-'
  }house-${transactionTypePart}-in-${addressPart}`;
};

const computePropertyStatus = (
  item: PropertyItem,
): CustomPropertyStatusTitle => {
  const info = item.info;

  const baitStatusId = info?.bait?.bait_status_id;
  if (baitStatusId) {
    if (BaitStatusesConstants.SOLD.includes(baitStatusId)) {
      return 'SSTC';
    }

    if (BaitStatusesConstants.LET_COMPLETED.includes(baitStatusId)) {
      return 'Let Agreed';
    }

    if (BaitStatusesConstants.UNDER_OFFER === baitStatusId) {
      return 'Under Offer';
    }
  }

  if (item.is_featured && item.flash_text) {
    return item.flash_text;
  }
  return null;
};

export const getImagesByCount = (property: PropertyItemComponent, imagesCount: number) => {
  return Array(imagesCount).fill(0).map((_, i) => {
    return property.images[i] || {
      background: NOT_FOUND_IMAGE,
    }
  });
}

const computeOnlineDate = (info: PropertyInfo) => {
  if (
    info.crucial_dates &&
    info.crucial_dates?.date_on_market &&
    info.crucial_dates?.date_on_market?.lifesycle_search
  ) {
    return info.crucial_dates?.date_on_market?.lifesycle_search;
  }
  return null;
};

export const mapProperty = (
  item: PropertyItem,
  index: number,
): PropertyItemComponent => {
  const info = item.info;
  const property = info.property;
  const medias: PropertyMediaComponent[] = getMedias(info.public_medias);
  const address = getAddress(info.head_title, property.property_address);

  const customPropertyStatusTitle = computePropertyStatus(item);
  const onlineDate = computeOnlineDate(info);
  const mapPosition = getPropertyPosition(info);
  const slug = generatePropertySlug(info, address);

  const contactsStore = useContactsStore();
  const isLogged = contactsStore.isLogged;
  const isLoginRequired = info.property_is_visible_for_everyone === false;

  return {
    slug,
    property_profile_id: info.property_profile_id,
    order: `0${index + 1}`,
    image:
      info.property_photo_single && info.property_photo_single.media_path
        ? `${CDN_URL}${info.property_photo_single.media_path.large}`
        : NOT_FOUND_IMAGE,
    bedroom: property.bedroom,
    bathroom: property.bathroom,
    price: getPrice(info.property_price_type_id, info.prices),
    reception: property.reception,
    advert_heading: getAdvertHeading(info.advert_heading, {
      transaction_type_id: info.transaction_type_id,
      bedroom: property.bedroom,
      property_type: {
        property_type_id: property.property_type?.property_type_id,
        name: property.property_type?.name,
      },
      property_style: {
        name: property.property_style?.name,
      },
    }),
    display_address: address,
    branch_phone_number: getBranchPhone(info.branch, info.transaction_type_id),
    branch_email: getBranchEmail(info.branch, info.transaction_type_id),
    features: info.property_profile_features?.features || [],
    medias,
    featured_video: medias.find((media) => media.isFiltered),
    virtual_tour: medias.find((media) => media.isVirtualTour),
    criteria_list: getCriteriaOfProperty(info),
    flood_list: getFloodOfProperty(info),
    additional_information_list: getAdditionalInformationOfProperty(info),
    lettings_criteria_list: getLettingsCriteriaOfProperty(info),
    is_rent: info.transaction_type_id === TransactionTypesConstants.LETTINGS,
    description: getDescription(info),
    summary: getSummary(info),
    images: medias.filter((media) => media.isImage),
    property_position: mapPosition,
    map_position: {
      lat: mapPosition?.lat,
      lng: mapPosition?.long,
    },
    floor_plans: getMediasByMapping(
      info.public_medias,
      MediaTypesConstants.FLOOR_PLAN,
    ),

    match_percentage: item.percentage,

    epcs: getMediasByMapping(
      info.public_medias,
      MediaTypesConstants.EPC_GRAPHS,
    ),
    extra_medias: getMediasByType(
      info.public_medias,
      MediaTypesConstants.MEDIA_CUSTOM_LINKS,
    ).map((media) => {
      return {
        name: media.name,
        extension: media.extension,
        mediaLink: media.media_link
          ? media.media_link
          : `${CDN_URL}${media.original_file_path}`,
      };
    }),
    custom_property_status_title: customPropertyStatusTitle,
    online_date: onlineDate,
    online_date_from_now: onlineDate ? fromNow(onlineDate) : '',
    transaction_type_id: info.transaction_type_id,
    branch_id: info.branch.branch_id,
    full_address: info.property?.property_address?.full_address,
    detail_page_url: `/properties/${slug}/${info.property_profile_id}`,
    is_locked: (() => {
      if (isLoginRequired) {
        return !isLogged;
      }
      return false;
    })(),
  };
};
