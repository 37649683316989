import { getVariable, useDataFromState } from '~/composables';
import type { Component } from '~/types';
import {
  type LoadingStates,
  type MultipleRepo,
  RepoStates,
  type SingleRepo,
  type States,
} from '~/units/core/types';
import type { RequestBody } from '~/server/bridges';
import { PFilterSearchType } from '~/units/properties/types';

type EntityName = 'Blog' | 'Blogs' | 'Property' | 'Properties';

const getEntityName = (stateName: States): EntityName | null => {
  if (stateName === 'blogsRepo') {
    return 'Blogs';
  } else if (stateName === 'singleBlogRepo') {
    return 'Blog';
  } else if (stateName === 'singlePropertyRepo') {
    return 'Property';
  } else if (stateName === 'propertiesRepo') {
    return 'Properties';
  }
  return null;
};

export type SingleFetchFunction = (slug: string) => Promise<{ data: never }>;

export type RequestFilter = {
  [key: string]: boolean | string | null | number;
};

export type MultipleFetchFunction = (
  limit: number,
  offset: number,
  order: string,
  filter: RequestFilter,
  body?: RequestBody,
) => Promise<{ data: never; meta?: { total?: number } }>;

export const fetchSingleResource = async (
  component: Component,
  fetchFunction: SingleFetchFunction,
  stateName: States,
  slugOrId: string,
) => {
  const entityName: EntityName | null = getEntityName(stateName);

  if (!entityName) {
    throw new Error('Entity doesnt exist');
  }

  const loadingStateName = `is${entityName}Loading` as LoadingStates;

  const { replaceData: setLoading } = useDataFromState(loadingStateName);

  setLoading(true);

  try {
    const { setData, data } = useDataFromState(stateName);
    const singleRepo: SingleRepo = <SingleRepo>data;

    const identifier = slugOrId;

    const singleRepoItem = singleRepo
      ? !!singleRepo.find((item) => item.identifier === identifier)
      : null;

    if (!singleRepoItem) {
      if (fetchFunction) {
        const result = await fetchFunction(identifier);
        setData([
          {
            data: result.data,
            query: {
              slug: slugOrId,
            },
            identifier,
          },
        ]);
      }
    }
  } catch (e) {
    // don't make anything
  }

  setLoading(false);
};

export const fetchMultipleResource = async (
  component: Component,
  fetchFunction: MultipleFetchFunction,
  stateName: States,
  { keyword }: RequestFilter,
) => {
  const entityName: EntityName | null = getEntityName(stateName);

  const searchType = (() => {
    if (
      [RepoStates.PROPERTIES_REPO, RepoStates.PROPERTIES_REPO].includes(
        stateName,
      )
    ) {
      const text = getVariable(component, 'transaction_type-text');

      if (text === 'sales') {
        return PFilterSearchType.SALES;
      } else if (text === 'lettings') {
        return PFilterSearchType.LETTINGS;
      } else if (text === 'both') {
        return null;
      }
      // return company's default search/transaction type later
      return PFilterSearchType.SALES;
    }
    return null;
  })();

  if (!entityName) {
    throw new Error('Entity doesnt exist');
  }

  const loadingStateName = `is${entityName}Loading` as LoadingStates;

  const { replaceData: setLoading } = useDataFromState(loadingStateName);

  setLoading(true);

  try {
    const { setData, data } = useDataFromState(stateName);
    const multipleRepoItems: MultipleRepo = <MultipleRepo>data;

    const variant = useVariant(component);
    const route = useRoute();
    const activePage = route.query.page ? +route.query.page : 1;
    const branchId: number | null | undefined = <number>(
      getVariable(component, 'branch_id-number')
    );
    const order = getVariable(component, 'order-text') as string;

    const identifier = `${variant}-${activePage}${
      keyword ? `-${keyword}` : ''
    }${branchId ? `-branchId:${branchId}` : ''}${
      searchType ? `-searchType:${searchType}` : ''
    }${order ? `-order:${order}` : ''}`;

    const repoItem = multipleRepoItems
      ? !!multipleRepoItems.find((item) => item.identifier === identifier)
      : null;

    if (!repoItem) {
      const limit: number =
        <number>getVariable(component, 'page_count-number') ||
        <number>getVariable(component, 'count-number') ||
        3;

      const offset = limit * (activePage - 1);
      const query: {
        offset: number;
        limit: number;
        activePage: number;
        keyword?: string | null | boolean;
        branch_id?: number;
        search_type?: PFilterSearchType.SALES | PFilterSearchType.LETTINGS;
      } = {
        offset,
        limit,
        activePage,
      };
      if (keyword) {
        query.keyword = keyword as string;
      }

      if (branchId) {
        query.branch_id = branchId;
      }

      if (searchType) {
        query.search_type = searchType;
      }

      const filter: {
        keyword: string | number | boolean | null;
        owner_branch_id?: number | undefined;
        search_type?: PFilterSearchType.SALES | PFilterSearchType.LETTINGS;
      } = {
        keyword,
      };

      if (branchId) {
        filter.owner_branch_id = branchId;
      }

      if (searchType) {
        filter.search_type = searchType;
      }

      if (fetchFunction) {
        const result = await fetchFunction(limit, offset, order, filter);

        setData([
          {
            data: result.data,
            total: result && result.meta ? result.meta.total : 0,
            query,
            identifier,
          },
        ]);
      }
    }
  } catch (e) {
    // don't make anything
  }

  setLoading(false);
};
